import useTranslation from '@helpers/useTranslation';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface PageMetaProps {
  description?: string;
  title: string;
}

const PageMeta: FC<PageMetaProps> = ({ description, title }) => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{`${title} - ${t('pomello')}`}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};

export default PageMeta;
