import { AuthType, User } from '@helpers/AuthClient';
import useSendToken from '@helpers/useSendToken';
import GoogleLoginView from '@views/GoogleLoginView';
import TransferTokenView from '@views/TransferTokenView';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const OauthGooglePageApp: FC = () => {
  const { sendToken, state, token } = useSendToken();

  const handleLoginSuccess = (user: User) => {
    sendToken(user);
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {state !== 'idle' ? (
        <TransferTokenView result={state} token={token} />
      ) : (
        <GoogleLoginView authType={AuthType.App} onSuccess={handleLoginSuccess} />
      )}
    </>
  );
};

export default OauthGooglePageApp;
