import BigLoader from '@components/BigLoader';
import PageMeta from '@components/PageMeta';
import Translate from '@components/Translate';
import { useAuth } from '@context/AuthContext';
import { AuthType, User } from '@helpers/AuthClient';
import getAuthPageUrl from '@helpers/getAuthPageUrl';
import useTranslation from '@helpers/useTranslation';
import AuthTemplate from '@templates/AuthTemplate';
import { Link } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { Content, Heading } from 'react-bulma-components';
import * as styles from './GoogleLoginView.module.scss';

interface GoogleLoginViewProps {
  authType: AuthType;
  onSuccess(user: User): void;
}

const GoogleLoginView: FC<GoogleLoginViewProps> = ({ authType, onSuccess }) => {
  const { t } = useTranslation(['googleLogin', 'pages']);

  const { authClient } = useAuth();

  const [tokenError, setTokenError] = useState<string>();

  useEffect(() => {
    authClient
      .loginWithGoogle()
      .then(user => {
        if (user) {
          onSuccess(user);
        }
      })
      .catch(error => {
        setTokenError(error.message);
      });
  }, [authClient, authType, onSuccess]);

  const loginUrl = getAuthPageUrl('login', authType);

  return (
    <AuthTemplate>
      <PageMeta title={t('pages:authorizeWithGoogle')} />
      <AuthTemplate.Content>
        {tokenError ? (
          <Content className={styles.content}>
            <Heading size={5}>{t('common:somethingWentWrong')}</Heading>
            <p>
              <Translate
                components={{ back: <Link to={loginUrl} /> }}
                message={t('goBackAndTryAgain')}
              />
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('contactSupport', { email: 'support@pomelloapp.com' }),
              }}
            />
            <p className={styles.errorCode}>
              <small>{t('errorCode', { code: tokenError })}</small>
            </p>
          </Content>
        ) : (
          <BigLoader />
        )}
      </AuthTemplate.Content>
    </AuthTemplate>
  );
};

export default GoogleLoginView;
