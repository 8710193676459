import cc from 'classcat';
import React, { FC, SVGProps } from 'react';
import * as styles from './Wordmark.module.scss';

const Wordmark: FC<SVGProps<SVGSVGElement>> = ({ className, width = 112, ...remainingProps }) => {
  return (
    <svg
      className={cc([className, styles.wordmark])}
      viewBox="0 0 140 50"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...remainingProps}
    >
      <path d="M19.9 2.2c2.6 0 4.8 0.3 6.8 1C28.6 4 30.2 4.9 31.5 6c1.3 1.2 2.3 2.5 2.9 4 0.6 1.5 1 3 1 4.7 0 1.5-0.3 3-0.9 4.5 -0.6 1.5-1.5 2.8-2.6 4.1 -1.2 1.2-2.6 2.3-4.4 3.2 -1.8 0.9-3.9 1.5-6.3 1.9 0 1.1-0.1 2.1-0.1 3.1 0 0.4 0 0.8 0 1.2 0 0.4 0 0.8 0 1.1s0 0.6 0 0.9c0 0.2 0 0.4 0 0.5 0 1.1-0.2 2.2-0.4 3.3 -0.2 1.2-0.5 2.3-1 3.4C19.3 43 18.8 44 18.2 45s-1.3 1.9-2.1 2.6c-0.8 0.7-1.7 1.3-2.7 1.8 -1 0.4-2.1 0.7-3.3 0.7 -1.3 0-2.4-0.3-3.4-1s-1.9-1.5-2.6-2.6 -1.4-2.4-1.9-3.8c-0.5-1.7-1-3.2-1.3-4.7 -0.3-1.6-0.6-3.1-0.7-4.7C0.1 31.6 0 30.2 0 28.7c0-2.4 0.2-4.8 0.7-7 0.4-2.2 1.1-4.4 1.9-6.3 0.8-2 1.8-3.8 3-5.4s2.5-3 4-4.2 3.1-2.1 4.8-2.7S18 2.2 19.9 2.2zM6.1 28.1c0 0.6 0 1.5 0 2.5 0 1.1 0.1 2.2 0.1 3.4 0.1 1.2 0.2 2.4 0.4 3.7 0.2 1.2 0.4 2.4 0.7 3.4 0.3 1 0.7 1.8 1.2 2.5 0.5 0.6 1.1 1 1.9 1 0.8 0 1.4-0.3 2-0.8s1-1.2 1.4-2.1c0.4-0.9 0.7-1.8 0.9-2.9 0.2-1.1 0.4-2.2 0.5-3.3s0.2-2.2 0.2-3.2 0-1.9 0-2.6v-1.1c-1.3-0.5-2.3-0.9-3-1.3s-1.3-0.8-1.6-1.1c-0.4-0.4-0.6-0.7-0.7-1.1C10 24.7 10 24.3 10 23.7c0-0.3 0-0.5 0-0.8s0.1-0.5 0.1-0.7c0.1-0.2 0.2-0.4 0.3-0.5s0.3-0.2 0.5-0.2 0.3 0 0.4 0.1c0.1 0.1 0.3 0.1 0.5 0.3 0.5 0.3 1 0.5 1.4 0.7s0.8 0.3 1.1 0.4 0.6 0.2 0.9 0.3c0-0.2 0-0.6 0-1.2s0.1-1.3 0.1-2c0.1-0.8 0.2-1.5 0.4-2.4 0.2-0.8 0.5-1.6 0.9-2.3 0.4-0.7 0.9-1.3 1.5-1.7 0.6-0.5 1.4-0.7 2.3-0.7 0.3 0 0.6 0 0.8 0.1 0.3 0.1 0.6 0.2 0.8 0.3 0.2 0.1 0.4 0.3 0.6 0.6 0.2 0.2 0.2 0.6 0.2 0.9 0 0.1 0 0.2 0 0.2s0 0.1 0 0.2l-1.4 5.9L21.2 23c1.5-0.2 2.8-0.6 3.9-1.1s2-1.1 2.7-1.8c0.7-0.7 1.2-1.4 1.6-2.3 0.3-0.8 0.5-1.7 0.5-2.5 0-0.9-0.2-1.7-0.5-2.6 -0.3-0.9-0.9-1.7-1.7-2.4 -0.8-0.7-1.9-1.3-3.3-1.8s-3.1-0.7-5.1-0.7c-1.5 0-2.9 0.3-4.2 0.9 -1.3 0.6-2.4 1.4-3.3 2.4 -1 1-1.8 2.2-2.5 3.5S8 17.4 7.5 18.9c-0.5 1.5-0.8 3.1-1 4.7C6.3 25 6.1 26.6 6.1 28.1z"></path>
      <path d="M44.1 26.9c0.4 0.9 0.8 1.8 1 2.8s0.3 1.9 0.3 2.8 -0.1 1.8-0.3 2.7 -0.5 1.8-0.9 2.7 -0.8 1.7-1.4 2.4c-0.5 0.7-1.2 1.4-1.9 1.9 -0.7 0.6-1.5 1-2.3 1.3S36.9 44 36 44c-1.3 0-2.5-0.3-3.5-0.9 -1-0.6-1.7-1.4-2.4-2.3 -0.6-0.9-1.1-2-1.3-3.2 -0.3-1.2-0.4-2.4-0.4-3.6 0-1.7 0.3-3.3 0.8-4.7 0.5-1.5 1.2-2.7 2.2-3.8 0.9-1.1 2.1-1.9 3.5-2.5s2.9-0.9 4.6-0.9c1.5 0 2.8 0.2 4.1 0.7s2.5 1.1 3.6 1.8c1.1 0.8 2.1 1.6 3.1 2.6s1.8 2 2.7 3l-0.4 3.2c-0.4-0.6-1-1.1-1.7-1.8 -0.7-0.6-1.4-1.3-2.2-1.9s-1.6-1.2-2.3-1.7c-0.8-0.5-1.4-1-2-1.4L44.1 26.9zM37 39.6c0.5 0 1-0.3 1.5-0.8s0.9-1.2 1.3-2c0.4-0.8 0.7-1.7 0.9-2.7s0.3-1.9 0.3-2.8c0-0.7-0.1-1.4-0.2-2 -0.1-0.6-0.3-1.1-0.5-1.6 -0.2-0.4-0.5-0.8-0.8-1.1 -0.3-0.3-0.7-0.4-1.2-0.4 -0.6 0-1.2 0.3-1.8 0.8s-1.1 1.2-1.5 2.1c-0.4 0.8-0.8 1.8-1 2.8s-0.4 2-0.4 2.9c0 0.6 0.1 1.2 0.2 1.8 0.1 0.6 0.3 1.1 0.6 1.5s0.6 0.8 1 1C35.9 39.5 36.4 39.6 37 39.6z"></path>
      <path d="M59.6 39.8c0-0.2 0-0.5 0-0.8 0-0.4 0.1-0.8 0.1-1.3 0.1-0.5 0.1-1 0.2-1.5 0.1-0.5 0.2-1.1 0.2-1.6 0.2-1.2 0.4-2.5 0.6-3.9h-0.3c-0.2 0.2-0.4 0.6-0.6 1 -0.2 0.5-0.6 1.1-1 2s-1 2-1.6 3.5c-0.7 1.4-1.5 3.2-2.6 5.3 -0.3 0.6-0.7 1.1-1.1 1.3 -0.4 0.2-0.9 0.3-1.3 0.3 -0.6 0-1.1-0.1-1.5-0.4 -0.4-0.3-0.7-0.6-0.9-1.1 -0.2-0.4-0.3-1-0.4-1.5 -0.1-0.6-0.1-1.2-0.1-1.8s0-1.4 0.1-2.2c0.1-0.9 0.2-1.8 0.3-2.7s0.3-1.9 0.4-2.9c0.2-1 0.3-1.9 0.5-2.8s0.4-1.7 0.5-2.5c0.2-0.8 0.4-1.4 0.5-1.8 0.2-0.5 0.3-0.9 0.4-1.2 0.1-0.3 0.3-0.5 0.5-0.7s0.4-0.3 0.6-0.3c0.2-0.1 0.6-0.1 0.9-0.1 0.6 0 1 0 1.4 0.1 0.3 0 0.6 0.1 0.7 0.3 0.2 0.1 0.3 0.4 0.3 0.6 0 0.3 0 0.7 0 1.1 0 0.3 0 0.6 0 0.9s-0.1 0.7-0.1 1.1 -0.1 0.8-0.2 1.2c-0.1 0.4-0.1 0.8-0.2 1.2 -0.1 0.9-0.3 1.8-0.4 2.8l0.3 0.3c0 0 0.2-0.3 0.5-0.9 0.3-0.5 0.7-1.2 1.1-1.9 0.4-0.7 0.9-1.5 1.4-2.4 0.5-0.8 1-1.5 1.4-2.2 0.4-0.6 0.9-1.1 1.4-1.6 0.5-0.4 1.1-0.6 1.8-0.6 0.6 0 1.2 0.2 1.6 0.6 0.5 0.4 0.7 0.9 0.7 1.6 0 0.6 0 1.2-0.1 1.7s-0.2 1.1-0.3 1.6c-0.1 0.5-0.2 1-0.4 1.5 -0.1 0.5-0.2 1-0.3 1.5 -0.1 0.4-0.1 0.6-0.1 0.7s0 0.2 0 0.2l0.3 0.3c0 0 0.2-0.3 0.5-0.9 0.3-0.5 0.7-1.2 1.2-1.9 0.5-0.8 1-1.5 1.5-2.4 0.5-0.8 1-1.6 1.5-2.2 0.4-0.6 0.9-1.1 1.3-1.5s1-0.6 1.6-0.6c0.7 0 1.3 0.2 1.8 0.6s0.7 0.9 0.7 1.6c0 1.1-0.1 2.1-0.2 3s-0.2 1.9-0.4 2.8c-0.1 0.9-0.3 1.8-0.4 2.8 -0.1 0.9-0.2 1.9-0.2 3 0 0.8 0.1 1.5 0.3 2 0.2 0.5 0.5 0.8 0.8 0.8s0.7-0.1 1-0.2c0.3-0.1 0.7-0.3 1.1-0.5s0.7-0.5 1.1-0.7c0.4-0.3 0.7-0.6 1-0.9 0.8-0.7 1.5-1.5 2.2-2.4l0.4 5.4c-0.7 0.5-1.3 1.1-2 1.7 -0.7 0.6-1.4 1.1-2.1 1.6s-1.6 0.8-2.4 1.1c-0.9 0.3-1.9 0.4-3 0.4 -0.6 0-1-0.2-1.4-0.5 -0.4-0.4-0.7-0.9-0.9-1.5 -0.2-0.6-0.4-1.4-0.5-2.3 -0.1-0.9-0.1-1.8-0.1-2.8s0-2 0.1-3.1 0.2-2.2 0.3-3.3h-0.3l-4.7 11.9c-0.4 0.6-0.8 1.1-1.3 1.3s-1.1 0.3-1.7 0.3c-0.5 0-0.9-0.1-1.2-0.4 -0.3-0.3-0.5-0.6-0.7-1s-0.3-0.9-0.3-1.4C59.6 40.7 59.6 40.2 59.6 39.8z"></path>
      <path d="M87.9 44c-1.4 0-2.7-0.3-3.8-0.8 -1.1-0.5-2-1.2-2.7-2.1 -0.7-0.9-1.3-1.9-1.6-3.1 -0.4-1.2-0.5-2.4-0.5-3.6 0-0.9 0.1-1.8 0.3-2.7 0.2-1 0.4-1.9 0.8-2.8 0.3-0.9 0.8-1.8 1.3-2.6 0.5-0.8 1.1-1.6 1.8-2.2 0.7-0.6 1.5-1.1 2.3-1.5 0.9-0.4 1.8-0.6 2.9-0.6 0.9 0 1.7 0.1 2.4 0.4 0.7 0.3 1.4 0.7 1.9 1.2s1 1.1 1.3 1.8c0.3 0.7 0.5 1.5 0.5 2.4s-0.2 1.9-0.7 2.8 -1.1 1.8-1.9 2.7c-0.8 0.8-1.8 1.6-2.9 2.2 -1.1 0.7-2.4 1.2-3.7 1.5 0.2 0.4 0.5 0.8 0.7 1 0.2 0.3 0.5 0.5 0.7 0.6 0.2 0.2 0.5 0.3 0.7 0.3C88 39 88.2 39 88.5 39c0.9 0 1.9-0.2 2.9-0.6 1-0.4 2-0.8 3-1.4 1-0.6 1.9-1.2 2.7-1.8 0.9-0.6 1.6-1.3 2.2-1.8l3 3.1c-1.3 1.4-2.8 2.6-4.3 3.7 -0.7 0.5-1.4 0.9-2.1 1.4 -0.8 0.4-1.5 0.8-2.4 1.2 -0.8 0.3-1.7 0.6-2.6 0.8C89.7 43.9 88.8 44 87.9 44zM84.9 33.3c0.6 0 1.1-0.1 1.8-0.4 0.6-0.3 1.3-0.6 1.8-1.1 0.6-0.4 1-0.9 1.4-1.4s0.6-1 0.6-1.5c0-0.7-0.1-1.3-0.4-1.7 -0.3-0.4-0.6-0.7-0.9-0.7 -0.7 0-1.2 0.1-1.7 0.4 -0.5 0.2-0.9 0.6-1.2 1 -0.3 0.4-0.6 0.8-0.8 1.3 -0.2 0.5-0.3 1-0.4 1.5s-0.2 1-0.2 1.5C84.9 32.5 84.9 32.9 84.9 33.3z"></path>
      <path d="M103.3 44c-0.8 0-1.6-0.4-2.3-1.1 -0.7-0.7-1.3-1.7-1.9-2.9 -0.5-1.2-0.9-2.6-1.2-4.3 -0.3-1.6-0.4-3.3-0.4-5.1 0-1.4 0.1-2.8 0.3-4.4 0.2-1.6 0.5-3.2 0.9-4.9 0.4-1.7 0.8-3.4 1.4-5.1 0.5-1.7 1.1-3.4 1.8-4.9 0.7-1.6 1.4-3 2.2-4.4 0.8-1.4 1.6-2.6 2.4-3.6 0.9-1 1.8-1.8 2.7-2.4C110 0.3 111 0 111.9 0c0.7 0 1.2 0.1 1.6 0.4s0.8 0.7 1 1.2 0.4 1 0.5 1.7c0.1 0.6 0.2 1.3 0.2 1.9 0 1.4-0.2 2.9-0.5 4.4s-0.8 3-1.3 4.5c-0.6 1.5-1.2 3-1.9 4.5 -0.7 1.5-1.5 3-2.3 4.5s-1.6 3-2.4 4.4 -1.6 2.9-2.3 4.2c-0.1 0.2-0.2 0.4-0.2 0.6 -0.1 0.2-0.2 0.5-0.2 0.8 -0.1 0.3-0.2 0.6-0.2 0.9 -0.1 0.3-0.1 0.6-0.2 0.9v0.3c0 0.4 0 0.8 0.1 1.2s0.2 0.7 0.3 1c0.1 0.3 0.3 0.5 0.4 0.7s0.3 0.3 0.4 0.3c0.3 0 0.6-0.1 1-0.2 0.4-0.1 0.8-0.3 1.2-0.5 0.4-0.2 0.9-0.4 1.3-0.7s0.9-0.5 1.3-0.8c1-0.6 2-1.4 3-2.2l1.9 4.8c-1.4 1-2.7 1.9-4 2.6 -0.6 0.3-1.2 0.6-1.8 0.9 -0.6 0.3-1.2 0.6-1.9 0.8 -0.6 0.2-1.2 0.4-1.8 0.6C104.4 44 103.8 44 103.3 44zM103.3 25.7c0.7-1.2 1.4-2.6 2.2-4.1 0.8-1.5 1.5-3.1 2.2-4.7 0.7-1.6 1.3-3.2 1.8-4.8s0.8-3.1 1-4.5c0-0.4 0-0.8 0-1.1 0-0.3-0.1-0.5-0.2-0.5 -0.4 0-0.9 0.3-1.4 0.9s-1 1.4-1.5 2.3c-0.5 1-1 2.1-1.5 3.4s-0.9 2.7-1.2 4.2c-0.4 1.5-0.7 3-0.9 4.5C103.4 22.7 103.3 24.2 103.3 25.7z"></path>
      <path d="M116.5 44c-0.8 0-1.6-0.4-2.3-1.1 -0.7-0.7-1.3-1.7-1.9-2.9 -0.5-1.2-0.9-2.6-1.2-4.3 -0.3-1.6-0.4-3.3-0.4-5.1 0-1.4 0.1-2.8 0.3-4.4 0.2-1.6 0.5-3.2 0.9-4.9 0.4-1.7 0.8-3.4 1.4-5.1 0.5-1.7 1.1-3.4 1.8-4.9 0.7-1.6 1.4-3 2.2-4.4 0.8-1.4 1.6-2.6 2.5-3.6s1.8-1.8 2.7-2.4c0.9-0.6 1.9-0.9 2.8-0.9 0.7 0 1.2 0.1 1.6 0.4s0.8 0.7 1 1.2c0.2 0.5 0.4 1 0.5 1.7 0.1 0.6 0.2 1.3 0.2 1.9 0 1.4-0.2 2.9-0.5 4.4s-0.8 3-1.3 4.5c-0.6 1.5-1.2 3-1.9 4.5 -0.7 1.5-1.5 3-2.3 4.5s-1.6 3-2.4 4.4 -1.6 2.9-2.3 4.2c-0.1 0.2-0.2 0.4-0.2 0.6 -0.1 0.2-0.2 0.5-0.2 0.8 -0.1 0.3-0.2 0.6-0.2 0.9 -0.1 0.3-0.1 0.6-0.2 0.9v0.3c0 0.4 0 0.8 0.1 1.2s0.2 0.7 0.3 1c0.1 0.3 0.2 0.5 0.4 0.7 0.1 0.2 0.3 0.3 0.4 0.3 0.3 0 0.6-0.1 1-0.2 0.4-0.1 0.8-0.3 1.2-0.5 0.4-0.2 0.9-0.4 1.3-0.7s0.9-0.5 1.3-0.8c1-0.6 2-1.4 3-2.2l1.9 4.8c-1.4 1-2.7 1.9-4 2.6 -0.6 0.3-1.2 0.6-1.8 0.9 -0.6 0.3-1.2 0.6-1.9 0.8 -0.6 0.2-1.2 0.4-1.8 0.6C117.6 44 117 44 116.5 44zM116.5 25.7c0.7-1.2 1.4-2.6 2.2-4.1 0.8-1.5 1.5-3.1 2.2-4.7 0.7-1.6 1.3-3.2 1.8-4.8s0.8-3.1 1-4.5c0-0.4 0-0.8 0-1.1 0-0.3-0.1-0.5-0.2-0.5 -0.4 0-0.9 0.3-1.4 0.9s-1 1.4-1.5 2.3c-0.5 1-1 2.1-1.5 3.4s-0.9 2.7-1.3 4.2 -0.7 3-0.9 4.5C116.6 22.7 116.5 24.2 116.5 25.7z"></path>
      <path d="M139.7 29.7c0.2 1 0.3 1.9 0.3 2.8 0 0.9-0.1 1.8-0.3 2.7s-0.5 1.8-0.9 2.7 -0.8 1.7-1.4 2.4c-0.5 0.7-1.2 1.4-1.9 1.9 -0.7 0.6-1.5 1-2.3 1.3s-1.7 0.5-2.6 0.5c-1.3 0-2.5-0.3-3.4-0.9 -1-0.6-1.7-1.4-2.4-2.3 -0.6-0.9-1.1-2-1.3-3.2 -0.3-1.2-0.4-2.4-0.4-3.6 0-1.7 0.2-3.3 0.8-4.7 0.5-1.5 1.2-2.7 2.2-3.8 0.9-1.1 2.1-1.9 3.5-2.5 1.4-0.6 2.7-1.1 4.4-0.8C136.7 22.7 138.9 25.5 139.7 29.7zM131.6 39.6c0.5 0 1-0.3 1.5-0.8s0.9-1.2 1.3-2c0.4-0.8 0.7-1.7 0.9-2.7s0.3-1.9 0.3-2.8c0-0.7-0.1-1.4-0.2-2 -0.1-0.6-0.3-1.1-0.5-1.6 -0.2-0.4-0.5-0.8-0.8-1.1 -0.3-0.3-0.7-0.4-1.2-0.4 -0.6 0-1.2 0.3-1.8 0.8s-1.1 1.2-1.5 2.1c-0.4 0.8-0.8 1.8-1 2.8s-0.4 2-0.4 2.9c0 0.6 0.1 1.2 0.2 1.8 0.1 0.6 0.3 1.1 0.6 1.5s0.6 0.8 1 1C130.5 39.5 131 39.6 131.6 39.6z"></path>
    </svg>
  );
};

export default Wordmark;
