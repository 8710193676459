import React, { FC } from 'react';
import { Columns } from 'react-bulma-components';

const AuthTemplateContent: FC = ({ children }) => {
  return (
    <Columns centered>
      <Columns.Column tablet={{ size: 6 }} desktop={{ size: 5 }}>
        {children}
      </Columns.Column>
    </Columns>
  );
};

export default AuthTemplateContent;
