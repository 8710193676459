import Wordmark from '@components/Wordmark';
import useTranslation from '@helpers/useTranslation';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import { Container, Section } from 'react-bulma-components';
import { Helmet } from 'react-helmet';
import * as styles from './AuthTemplate.module.scss';
import AuthTemplateContent from './AuthTemplateContent';
import AuthTemplateFooter from './AuthTemplateFooter';

interface AuthTemplateComponent extends FC {
  Content: typeof AuthTemplateContent;
  Footer: typeof AuthTemplateFooter;
}

const AuthTemplate: AuthTemplateComponent = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <Helmet htmlAttributes={{ class: styles.background }} />
      <div className={styles.header}>
        <Link to="/" title={t('backToHomePage')}>
          <Wordmark />
        </Link>
      </div>
      <Container renderAs="main">{children}</Container>
    </Section>
  );
};

AuthTemplate.Content = AuthTemplateContent;
AuthTemplate.Footer = AuthTemplateFooter;

export default AuthTemplate;
